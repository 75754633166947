import React, { ReactNode } from "react"
import {
  Container,
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core"

interface LayoutProps {
  children: ReactNode | ReactNode[]
}

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
})

export default function Layout({ children }: LayoutProps) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="lg">{children}</Container>
    </ThemeProvider>
  )
}
